.hostname-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-sm);
    background-color: var(--color-grey-light);
    color: var(--color-grey-dark);

    & div > span {
        &.hostname-form__result--highligthed {
            color: var(--color-midnight-light);
        }
    }

    &--verified {
        background-color: var(--color-success-lighter);
        border-color: var(--color-success-light);
        & i {
            color: var(--color-success-main);
        }
    }
}