.container-create {
    &__header {
        margin: 0;
        padding: calc(var(--spacing-lg) * 2);
        padding-bottom: var(--spacing-md);
    }

    &__form,
    &__tutorial,
    &__rocket {
        width: 50%;
        padding: var(--spacing-lg) calc(var(--spacing-lg) * 2);
    }

    &__tutorial {
        background-color: var(--color-ocean-lighter);
    }

    &__rocket {
        text-align: center;
    }

    &__close {
        position: absolute;
        top: var(--spacing-md);
        right: var(--spacing-md);

        & i {
            font-size: $font-size-28;
        }
    }

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
        &__header {
            padding: var(--spacing-md);
            padding-top: calc(var(--spacing-lg) * 2);
        }

        &__form {
            padding: var(--spacing-md);
        }

        &__form,
        &__tutorial {
            width: 100%;
        }
    }
}

.modal-welcome {
    & svg {
        height: 10rem;
    }
}
