.container-details {
  .container-header {
    width: 100%;
    color: var(--color-midnight-light);

    &__title {
      height: 3rem;

      &__icon {
        font-size: $font-size-36;
        user-select: none;
      }
    }

    &__infos {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      column-gap: var(--spacing-md);
      row-gap: var(--spacing-xs);

      &__item {
        &__label {
          color: var(--color-grey-dark);
          margin-right: var(--spacing-sm);
        }
      }
    }
  }

  &-configuration {
    width: 100%;

    & .add-hostname-button {
      background-color: var(--color-grey-light);
      border-color: var(--color-grey);
    }
  }

  &-advanced-configuration {
    &__box {
      position: relative;
    }

    &--blocked {
      & .container-details-advanced-configuration__box::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-grey-light);
        border-radius: var(--border-radius-md);
        opacity: .5;
      }
    }

    .sub-configuration-box {
      position: relative;
      background-color: var(--color-grey-light);

      &--blocked::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-grey-light);
        border-radius: var(--border-radius-md);
        opacity: .5;
      }
    }
  }
}

.analytics {
  &__hits {
    &__metric {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: var(--spacing-lg);
  
      &__header {
        font-size: $font-size-16;
        color: var(--color-grey-dark);
      }
  
      &__value {
        color: var(--color-cyan);
        font-size: $font-size-40;
        font-weight: $font-weight-bold;
        line-height: 1;
      }
    }
  }
}

.refresh-icon {
  user-select: none;

  &--animate {
    animation: rotate 0.5s linear infinite;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
