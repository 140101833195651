.help-carousel {
    max-width: 50rem;
    margin: 0 auto;

    & .splide__track {
        background-color: var(--color-white);
        border-radius: var(--border-radius-md);
        border: 1px solid var(--color-grey);
        box-shadow: none;
    }

    & .splide__pagination__page.is-active {
        background-color: var(--color-ocean)!important;
    }
}

.help-carousel-slide {
    &__image  {
        padding: var(--spacing-md);
    }

    &__content {
        padding: var(--spacing-md);
        background-color: var(--color-white);

        &__text {
            margin-top: var(--spacing-xs);
        }
    }
}
