.access {
    .activation-section {
        margin: 0 auto;
        max-width: 80rem;
    }

    .activation-card {
        max-width: 40rem;
        margin: var(--spacing-sm);
        padding: calc(var(--spacing-md) * 2) !important;

        &__title span {
            color: var(--color-green);
        }

        &__terms {
            margin-left: var(--spacing-sm);
            color: var(--color-midnight);
        }
    }

    .pricing {
        &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--spacing-sm);
            align-items: center;
            text-align: center;
            width: 100%;
            max-width: 25rem;
            margin-bottom: var(--spacing-md);
            user-select: none;

            & .h1 {
                padding: 0 var(--spacing-lg);
            }

            &__tax {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: var(--spacing-xs);
                font-size: $font-size-20;
                font-weight: $font-weight-bold;
                color: var(--color-midnight-lighter);

                &__price {
                font-size: $font-size-70;
                font-weight: $font-weight-semi-bold;
                color: var(--color-green);
                }
            }
        }
    }
}
